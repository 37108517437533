<template>
  <div id="login" class="common-login">
    <div class="top">
      <div class="top-content">
        <div class="logo">
          <img src="../../assets/login/logo.png" />
        </div>
      </div>
    </div>
    <div class="content">
      <div class="login" v-if="type === 0">
        <div class="title">
          <span>欢迎使用传蔬运营后台</span>
        </div>
        <div
          class="error-msg"
          style="top: 35px;left:165px"
          v-if="loginMsg !== ''"
        >
          {{ loginMsg }}
        </div>
        <span>手机号：</span>
        <input
          type="text"
          placeholder="请输入手机号"
          maxlength="11"
          v-model="login.username"
          @input="usernameInput($event)"
          @blur="usernameBlur($event)"
          style="border:1px solid #257873"
        />
        <br />
        <span style="margin-right:10px">密 码：</span>
        <input
          type="password"
          placeholder="请输入密码"
          v-on:keyup.enter="loginUser"
          minlength="8"
          maxlength="16"
          v-model="login.password"
          style="border:1px solid #257873"
        />
        <button
          @click="loginUser"
          style="background:#257873;width:382px;height:40px"
        >
          登录
        </button>
        <a @click="toForget">忘记密码？</a>
      </div>
      <div class="create" v-if="type === 1">
        <div class="title">
          <span>创建密码</span>
        </div>
        <div class="error-msg" style="top: 32px;" v-if="createMsg !== ''">
          {{ createMsg }}
        </div>
        <input
          type="text"
          placeholder="请输入手机号"
          maxlength="11"
          v-model="create.mobile"
          @input="usernameInput($event)"
        />
        <span @click="getSms" v-if="!isDown">获取验证码</span>
        <span v-if="isDown">重新发送({{ fillZero(countDown) }})</span>
        <input
          type="text"
          placeholder="请输入验证码"
          v-model="create.sms_code"
        />
        <input
          type="password"
          placeholder="设置密码"
          minlength="8"
          maxlength="16"
          v-model="create.password"
        />
        <button @click="createPassword">确认</button>
        <a @click="toLogin">返回登录</a>
      </div>
      <div class="forget" v-if="type === 2">
        <div class="title">
          <span>重置密码</span>
        </div>
        <div
          class="error-msg"
          style="top: 35px;left:135px"
          v-if="forgetMsg !== ''"
        >
          {{ forgetMsg }}
        </div>
        <span>手机号：</span>
        <input
          type="text"
          placeholder="请输入手机号"
          maxlength="11"
          v-model="forget.mobile"
          @input="usernameInput($event)"
          style="border:1px solid #257873"
        />
        <span @click="getSms" v-if="!isDown">获取验证码</span>
        <span v-if="isDown">重新发送({{ fillZero(countDown) }})</span>
        <br />
        <span>验证码：</span>
        <input
          type="text"
          placeholder="请输入验证码"
          v-model="forget.sms_code"
          maxlength="6"
          style="border:1px solid #257873"
        />
        <br />
        <span>新密码：</span>
        <input
          type="password"
          placeholder="新密码"
          minlength="8"
          maxlength="16"
          v-model="forget.new_password"
          style="border:1px solid #257873"
        />
        <button
          @click="forgetPassword"
          style="background:#257873;width:382px;height:40px"
        >
          确认
        </button>
        <a @click="toLogin">返回登录</a>
      </div>
    </div>
    <div class="foot">
      <span>Copyright ©{{ year }} 上海传蔬信息技术有限公司</span>
      <!-- <span>杭州速农网络技术有限公司</span> -->
      <!-- <span>www.rongyi.com</span> -->
    </div>
  </div>
</template>

<script>
/**
 * @module app/login/login
 * @desc 传蔬-农贸版后台 登录
 * @version 0.0.1
 * @author songkexin <songkexin@rongyi.com>
 * @date   2021-01-10
 * @copyright 2021
 */
// import Vue from 'vue';
import { mapGetters, mapActions } from "vuex";
import { apiPost, fillZero } from "../../modules/utils";
import APIs from "../../modules/APIs";
import * as URL from "../../modules/URLs";
import router from "@/router";
// import * as CONST from '_/CONSTs';
const getters = mapGetters([]);
const actions = mapActions(["setAlert", "setUserInfo", "setSideMenu"]);
export default {
  name: "login",
  computed: {
    ...getters,
  },
  data() {
    return {
      type: 0, // 0: 登录, 1:创建密码, 2:忘记密码
      login: {
        username: "",
        password: "",
      },
      loginMsg: "",
      forget: {
        mobile: "",
        sms_code: "",
        code_verify_str: "",
        new_password: "",
      },
      forgetMsg: "",
      create: {
        mobile: "",
        sms_code: "",
        code_verify_str: "",
        password: "",
      },
      createMsg: "",
      isDown: false, // 正在倒计时
      countDown: 60, // 倒计时的时间
      intervalId: "", // 倒计时方法的id
      year: new Date().getFullYear(),
    };
  },
  watch: {
    isDown(newValue, oldValue) {
      if (newValue && !oldValue) {
        // 开始倒计时
        this.intervalId = setInterval(() => {
          if (this.countDown <= 0) {
            clearInterval(this.intervalId);
            this.isDown = false;
          } else {
            this.countDown -= 1;
          }
        }, 1000);
      }
    },
  },
  methods: {
    ...actions,
    fillZero(num, bit = 2) {
      return fillZero(num, bit);
    },
    /**
     * 手机号输入的校验
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-01-10
     * @param  {Object}   event
     */
    usernameInput(event) {
      let data = event.target.value;
      let reg = /\d+/;
      if (data !== "") {
        data = reg.exec(data);
        if (data === null) {
          data = "";
        } else {
          data = data[0];
        }
      }
      event.target.value = data;
      if (this.type === 0) {
        this.login.username = data;
      } else if (this.type === 1) {
        this.create.mobile = data;
      } else {
        this.forget.mobile = data;
      }
    },
    /**
     * 用户名失去焦点的校验
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-09-14
     * @param  {Object}   event
     */
    usernameBlur(event) {
      this.loginMsg = "";
      let $this = this;
      if (this.login.username !== "") {
        apiPost({
          url: APIs.LOGIN,
          data: {
            action: "account.first.login.verify",
            mobile: this.login.username,
          },
          success(res) {
            if (res.code + "" === "0") {
              if (res.data.is_first_login === 1) {
                $this.toCreate();
              }
            } else {
              $this.loginMsg = res.msg;
            }
          },
          error(res) {
            $this.loginMsg = "校验手机号失败";
          },
        });
      }
    },
    /**
     * 清空登录的表单
     * @author wurongjian <wurongjian@rongyi.com>
     * @date   2021-10-10
     */
    clearLogin() {
      this.loginMsg = "";
      this.login = {
        username: "",
        password: "",
      };
    },
    /**
     * 清空忘记密码的表单
     * @author wurongjian <wurongjian@rongyi.com>
     * @date   2021-10-10
     */
    clearForget() {
      this.forgetMsg = "";
      this.forget = {
        mobile: "",
        sms_code: "",
        code_verify_str: "",
        new_password: "",
      };
    },
    /**
     * 创建密码
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-09-14
     */
    toCreate() {
      this.clearCreate();
      this.type = 1;
      this.create.mobile = this.login.username;
      this.clearDown();
    },
    /**
     * 清空创建密码的表单
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-09-14
     */
    clearCreate() {
      this.createMsg = "";
      this.create = {
        mobile: "",
        sms_code: "",
        code_verify_str: "",
        password: "",
      };
    },
    /**
     * 清除倒计时
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-09-14
     */
    clearDown() {
      this.isDown = false;
      this.countDown = 60;
      clearInterval(this.intervalId);
    },
    /**
     * 获取验证码
     * @author wurongjian <wurongjian@rongyi.com>
     * @date   2021-10-10
     */
    getSms() {
      let $this = this;
      let mobile = "";
      this.forgetMsg = "";
      if (this.type === 1) {
        mobile = this.create.mobile;
      } else if (this.type === 2) {
        mobile = this.forget.mobile;
      }
      apiPost({
        url: APIs.SMS,
        data: {
          action: "code.send",
          mobile: mobile,
          type: "reset_password_web",
        },
        success(res) {
          if (res.code + "" === "0") {
            $this.isDown = true;
            $this.countDown = 60;
          } else {
            if ($this.type === 1) {
              $this.createMsg = res.msg;
            } else {
              $this.forgetMsg = res.msg;
            }
          }
        },
        error(res) {
          if ($this.type === 1) {
            $this.createMsg = "获取验证码失败";
          } else {
            $this.forgetMsg = "获取验证码失败";
          }
        },
      });
    },
    /**
     * 跳到忘记密码
     * @author wurongjian <wurongjian@rongyi.com>
     * @date   2021-10-10
     */
    toForget() {
      this.clearForget();
      this.type = 2;
      this.forget.mobile = this.login.username;
      this.clearDown();
    },
    /**
     * 欢迎回来， 进入登录页面
     * @author wurongjian <wurongjian@rongyi.com>
     * @date   2021-10-10
     */
    toLogin() {
      this.clearLogin();
      this.type = 0;
      this.clearDown();
    },
    /**
     * 创建密码
     * @author wurongjian <wurongjian@rongyi.com>
     * @date   2021-10-10
     */
    createPassword() {
      let $this = this;
      if (this.create.password.length < 8) {
        $this.createMsg = "密码位数必须大于等于8位";
        return false;
      }
      apiPost({
        url: APIs.SMS,
        data: {
          action: "code.verify",
          mobile: this.create.mobile,
          code: this.create.sms_code,
          type: "reset_password_web",
        },
        success(res) {
          if (res.code + "" === "0") {
            $this.create.code_verify_str = res.data;
            apiPost({
              url: APIs.LOGIN,
              data: {
                action: "account.reset.password",
                mobile: $this.create.mobile,
                new_password: $this.create.password,
                sms_code: $this.create.sms_code,
                code_verify_str: $this.create.code_verify_str,
              },
              success(res) {
                if (res.code + "" === "0") {
                  $this.toLogin();
                } else {
                  $this.createMsg = res.msg;
                }
              },
              error(res) {
                $this.createMsg = "创建密码失败";
              },
            });
          } else {
            $this.createMsg = res.msg;
          }
        },
        error(res) {
          $this.createMsg = "验证码验证失败";
        },
      });
    },
    /**
     * 保存 忘记密码
     * @author wurongjian <wurongjian@rongyi.com>
     * @date   2021-10-10
     */
    forgetPassword() {
      let $this = this;
      if (this.forget.new_password.length < 8) {
        $this.forgetMsg = "密码位数必须大于等于8位";
        return false;
      }
      apiPost({
        url: APIs.SMS,
        data: {
          action: "code.verify",
          mobile: this.forget.mobile,
          code: this.forget.sms_code,
          type: "reset_password_web",
        },
        success(res) {
          if (res.code + "" === "0") {
            $this.forget.code_verify_str = res.data;
            apiPost({
              url: APIs.LOGIN,
              data: {
                action: "account.reset.password",
                mobile: $this.forget.mobile,
                new_password: $this.forget.new_password,
                sms_code: $this.forget.sms_code,
                code_verify_str: $this.forget.code_verify_str,
              },
              success(res) {
                if (res.code + "" === "0") {
                  $this.toLogin();
                } else {
                  $this.forgetMsg = res.msg;
                }
              },
              error(res) {
                $this.forgetMsg = "重置密码失败";
              },
            });
          } else {
            $this.forgetMsg = res.msg;
          }
        },
        error(res) {
          $this.forgetMsg = "验证码验证失败";
        },
      });
    },
    /**
     * 登录
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-01-10
     */
    loginUser() {
      let $this = this;
      // if(!this.login.username){
      // 	this.setAlert({msg: '请输入手机号'});
      // 	return;
      // }
      // if(!this.login.password){
      // 	this.setAlert({msg: '请输入密码'});
      // 	return;
      // }
      apiPost({
        url: APIs.LOGIN,
        data: {
          action: "account.login",
          mobile: this.login.username,
          password: this.login.password,
        },
        success(res) {
          if (res.code === "0") {
            // $this.getMenus();
            router.push(URL.VERSION);
          } else {
            $this.loginMsg = res.msg;
          }
        },
        error(res) {
          $this.loginMsg = "登录失败";
        },
      });
    },
    /**
     * 获取菜单
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-01-10
     */
    getMenus() {
      let $this = this;
      apiPost({
        url: APIs.LOGIN,
        data: {
          action: "account.menu",
        },
        success(res) {
          if (res.code === "0") {
            $this.setSideMenu(res.data);
          } else {
          }
        },
        error(res) {},
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    next();
    // Vue.http.post(APIs.ACCOUNT, {
    // 	action: 'account.current'
    // })
    // .then(res => {
    // 	if(res.data.code === '0'){
    // 		router.push(URL.HOME);
    // 	}
    // 	else if(res.data.code === CONST.CODE_NOMARKET){
    // 		router.push(URL.SELMARKET);
    // 	}
    // 	else if(res.data.code === CONST.CODE_NOLOGIN){
    // 		next(vm => {
    // 			vm.getLocation();
    // 			window.addEventListener('resize', vm.getLocation, false);
    // 		});
    // 	}
    // }, res => {});
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getLocation, false);
  },
};
</script>

<style lang="less">
// @import (reference) "~less/login.less";
@import url("../../less/login.less");
.common-login .content .login {
  .mgt(20px);
}
#login {
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    // border: 1px solid #CCC!important;
  }
}
.common-login {
  .top {
    height: 350px;
    background: url("../../assets/login/bg.png") no-repeat;
    background-size: 100%;
    .top-content {
      .logo {
        margin-top: 50px;
        margin-left: -90px;
        img {
          width: 108px;
          height: 35px;
        }
      }
    }
  }
  .content {
    width: 619px;
  }
}
</style>
